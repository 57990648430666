<template>
    <SpaceDataList :spaceData="researchSpaces" :spaceType="spaceTypes.RESEARCH_SPACE" spaceIcon="mdi-beaker" headerTitle="Research Projects"></SpaceDataList>
</template>

<script>
import { mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
const SpaceDataList = () => import('../components/TheHomeSpaceTable')

export default {
    mixins: [enumsData],
    components: { SpaceDataList },
    computed: {
        ...mapState('orgStore', ['researchSpaces'])
    }
}
</script>
