var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SpaceDataList", {
    attrs: {
      spaceData: _vm.researchSpaces,
      spaceType: _vm.spaceTypes.RESEARCH_SPACE,
      spaceIcon: "mdi-beaker",
      headerTitle: "Research Projects"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }